import axiosInst from '../../axios';
import appHistory from '../../history';

const categories = {
  state: {
    status: '',
    categoryStatus: '',
    categoryData: {},
    itemsList: [],
    pagination: {
      totalPages: 1,
      totalObjects: 0,
      currentPage: 1
    },
    error: null
  },
  reducers: {
    fetchById: state => ({
      ...state,
      categoryStatus: 'waiting',
      error: null,
      categoryData: {}
    }),
    fetchByIdSuccess: (state, res) => ({
      ...state,
      categoryStatus: 'received',
      categoryData: res.data.data
    }),
    fetchByIdFailed: (state, error) => ({ ...state, categoryStatus: 'failed', error }),
    resetRoutine: state => ({ ...state, categoryStatus: '', error: null, categoryData: {} }),

    fetchBootstrapData: state => ({ ...state, status: 'waiting', error: null }),
    fetchBootstrapDataSuccess: (state, data) => ({
      ...state,
      status: 'received',
      ...data[0]
    }),
    fetchBootstrapDataFailed: (state, error) => ({ ...state, status: 'failed', error }),

    createUpdateRoutine: state => ({ ...state, status: 'waiting', error: null }),
    createUpdateRoutineSuccess: state => ({ ...state, status: 'received' }),
    createUpdateRoutineFailed: (state, error) => ({ ...state, status: 'failed', error }),

    fetchItems: state => ({ ...state, status: 'waiting', error: null }),
    fetchItemsSuccess: (state, res) => ({
      ...state,
      status: 'received',
      itemsList: res.data.data.categories,
      pagination: { ...res.data.data.pagination }
    }),
    fetchItemsFailed: (state, error) => ({ ...state, status: 'failed', error }),

    // ROUTINES TOGGLE STATUS ACTIONS
    toggleStatus: state => ({ ...state, status: 'waiting', error: null }),
    toggleStatusSuccess: state => ({ ...state, status: 'received' }),
    toggleStatusFailed: (state, error) => ({ ...state, status: 'failed', error })
  },
  effects: dispatch => ({
    fetchBootstrapData() {
      const promises = [axiosInst.get('/routine-categories')];

      Promise.all(promises)
        .then(res => {
          dispatch.categories.fetchBootstrapDataSuccess(res.map(innerRes => innerRes.data.data));
        })
        .catch(error => {
          dispatch.categories.fetchBootstrapDataFailed(error);
          console.log(error);
        });
    },

    createUpdateRoutine(payload) {
      const { method, body, id, enqueueSnackbar } = payload;
      const url = method === 'post' ? 'routine-categories' : `routine-categories/${id}`;

      const message =
        method === 'post'
          ? 'Categori­a creada correctamente.'
          : 'Categori­a actualizada correctamente.';

      axiosInst[`${method}`](url, body)
        .then(() => {
          dispatch.routines.createUpdateRoutineSuccess();
          appHistory.push('/app/categories');
          enqueueSnackbar(message, { variant: 'success' });
        })
        .catch(error => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }

          this.createUpdateRoutineFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    createUpdateList(payload) {
      const { method, body, id, enqueueSnackbar } = payload;
      const url = method === 'post' ? 'routine-categories/save-order' : `routine-categories/${id}`;

      const message = 'Lista actualizada correctamente.';

      axiosInst[`${method}`](url, body)
        .then(() => {
          dispatch.routines.createUpdateRoutineSuccess();
          appHistory.push('/app/categories');
          enqueueSnackbar(message, { variant: 'success' });
        })
        .catch(error => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }

          this.createUpdateRoutineFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    }
  })
};

export default categories;
