import axiosInst from '../../axios';
import appHistory from '../../history';

const routines = {
  state: {
    status: '',
    routineStatus: '',
    routineData: {},
    itemsList: [],
    pagination: {
      totalPages: 1,
      totalObjects: 0,
      currentPage: 1
    },
    error: null,
    bodyzones: [],
    bodyparts: [],
    exerciseslist: []
  },
  reducers: {
    fetchById: state => ({
      ...state,
      routineStatus: 'waiting',
      error: null,
      routineData: {}
    }),
    fetchByIdSuccess: (state, res) => ({
      ...state,
      routineStatus: 'received',
      routineData: res.data.data
    }),
    fetchByIdFailed: (state, error) => ({ ...state, routineStatus: 'failed', error }),
    resetRoutine: state => ({ ...state, routineStatus: '', error: null, routineData: {} }),

    fetchBootstrapData: state => ({ ...state, status: 'waiting', error: null }),
    fetchBootstrapDataSuccess: (state, data) => ({
      ...state,
      status: 'received',
      bodyzones: data[0],
      bodyparts: data[1],
      exerciseslist: data[2]
    }),
    fetchBootstrapDataFailed: (state, error) => ({ ...state, status: 'failed', error }),

    createUpdateRoutine: state => ({ ...state, status: 'waiting', error: null }),
    createUpdateRoutineSuccess: state => ({ ...state, status: 'received' }),
    createUpdateRoutineFailed: (state, error) => ({ ...state, status: 'failed', error }),

    fetchItems: state => ({ ...state, status: 'waiting', error: null }),
    fetchItemsSuccess: (state, res) => ({
      ...state,
      status: 'received',
      itemsList: res.data.data.entities,
      pagination: { ...res.data.data.pagination }
    }),
    fetchItemsFailed: (state, error) => ({ ...state, status: 'failed', error }),

    // ROUTINES TOGGLE STATUS ACTIONS
    toggleStatus: state => ({ ...state, status: 'waiting', error: null }),
    toggleStatusSuccess: state => ({ ...state, status: 'received' }),
    toggleStatusFailed: (state, error) => ({ ...state, status: 'failed', error })
  },
  effects: dispatch => ({
    fetchBootstrapData() {
      const promises = [
        axiosInst.get('/routines/bodyzones'),
        axiosInst.get('/routines/bodyparts'),
        axiosInst.get('/routines/exerciseslist')
      ];

      Promise.all(promises)
        .then(res => {
          dispatch.routines.fetchBootstrapDataSuccess(res.map(innerRes => innerRes.data.data));
        })
        .catch(error => {
          dispatch.routines.fetchBootstrapDataFailed(error);
          console.log(error);
        });
    },

    createUpdateRoutine(payload) {
      const { method, body, id, enqueueSnackbar } = payload;
      const url = method === 'post' ? '/routines' : `/routines/${id}`;

      const message =
        method === 'post' ? 'Rutina creada correctamente.' : 'Rutina actualizada correctamente.';

      axiosInst[`${method}`](url, body)
        .then(() => {
          dispatch.routines.createUpdateRoutineSuccess();
          appHistory.push('/app/routines');
          enqueueSnackbar(message, { variant: 'success' });
        })
        .catch(error => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }

          this.createUpdateRoutineFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    }
  })
};

export default routines;
