export const LANGUAGES = [
  { id: 2, name: 'Inglés', shortName: 'EN' },
  { id: 3, name: 'Alemán', shortName: 'GR' }
];

export const ROLES = {
  adm: 'admin',
  cs: 'customer service',
  sch: 'scholarship',
  rep: 'reporter'
};

export class Utils {
  static mapUrlParams(str) {
    return str.split('&').reduce((prev, curr) => {
      const param = curr.split('=');
      // eslint-disable-next-line no-param-reassign
      prev[decodeURIComponent(param[0])] = decodeURIComponent(param[1]);
      return prev;
    }, {});
  }

  static currency(number, local = 'en-US', currency = 'USD') {
    const val = number || 0;
    return new Intl.NumberFormat(local, { style: 'currency', currency }).format(val);
  }

  static bytesToSize(bytes, decimals = 2) {
    if (bytes === 0) return '0 Bytes';

    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

    const i = Math.floor(Math.log(bytes) / Math.log(k));

    // eslint-disable-next-line no-restricted-properties
    return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
  }

  static generateKey(pre) {
    return `${pre}_${new Date().getTime()}`;
  }

  static timeToMinuesQty(time) {
    const [hours, minutes] = time.split(':');
    return +hours * 60 + +minutes;
  }

  static validateUserPermissions(userRoles, permissions) {
    return userRoles.some(item => permissions.includes(item));
  }
}
