import PropTypes from 'prop-types';

export const routerMatch = {
  isExact: PropTypes.bool,
  params: PropTypes.object,
  path: PropTypes.string,
  url: PropTypes.string
};

export const routerLocation = {
  hash: PropTypes.string,
  key: PropTypes.string,
  pathname: PropTypes.string,
  search: PropTypes.string,
  state: PropTypes.object
};

export const routerHistory = {
  length: PropTypes.number,
  action: PropTypes.string,
  location: PropTypes.object,
  push: PropTypes.func,
  replace: PropTypes.func,
  go: PropTypes.func,
  goBack: PropTypes.func,
  goForward: PropTypes.func,
  block: PropTypes.func
};
