/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Router, Route, Switch, Redirect } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import SignIn from './components/Auth/SignIn';
import Loader from './components/UI/Loader/Loader';
import appHistory from './history';
import GetApp from './components/GetApp/GetApp';

const Error = React.lazy(() => import('./components/Error'));
const Layout = React.lazy(() => import('./components/Layout/Layout'));
const Unauthorized = React.lazy(() => import('./components/Unauthorized'));

const App = () => {
  const session = JSON.parse(localStorage.getItem('iCanSession'));
  const auth = useSelector(state => state.auth);
  const dispatch = useDispatch();

  const routes =
    (session && session.token) || auth.token ? (
      <Switch>
        <Redirect path='/' exact to='/app' />
        <Redirect path='/auth' to='/app' />
        <Route path='/get-app' component={GetApp} />
        {/* prettier-ignore */}
        <Route path='/app' render={cprops => (<Suspense fallback={<Loader />}><Layout {...cprops} /></Suspense>)} />
        {/* prettier-ignore */}
        <Route path='/unauthorized' render={cprops => (<Suspense fallback={<Loader />}><Unauthorized {...cprops} /></Suspense>)} />
        {/* prettier-ignore */}
        <Route path='/error' render={cprops => (<Suspense fallback={<Loader />}><Error {...cprops} /></Suspense>)} />
        <Redirect to='/error' />
      </Switch>
    ) : (
      <Switch>
        <Redirect path='/' exact to='/auth' />
        <Route path='/auth' component={SignIn} />
        {/* prettier-ignore */}
        <Route path='/unauthorized' render={cprops => (<Suspense fallback={<Loader />}><Unauthorized {...cprops} /></Suspense>)} />
        {/* prettier-ignore */}
        <Route path='/error' render={cprops => (<Suspense fallback={<Loader />}><Error {...cprops} /></Suspense>)} />
        <Route path='/get-app' component={GetApp} />
        <Redirect path='/app' to='/' />
        <Redirect to='/error' />
      </Switch>
    );

  React.useEffect(() => {
    if (session) {
      // recovered session, saved on reedux store
      dispatch({ type: 'auth/recoverUserSession', payload: session });
    }
  }, []);

  return (
    <SnackbarProvider
      maxSnack={3}
      autoHideDuration={3000}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right'
      }}>
      <Router history={appHistory}>{routes}</Router>
    </SnackbarProvider>
  );
};

export default App;
