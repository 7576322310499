import React from 'react';
import { Typography, Button, Card, LinearProgress, TextField } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import css from './Auth.module.scss';

const AuthSignIn = () => {
  const { enqueueSnackbar } = useSnackbar();
  const { register, handleSubmit, errors } = useForm({ submitFocusError: true });
  const authState = useSelector(state => ({ status: state.auth.status, error: state.auth.error }));
  const dispatch = useDispatch();

  return (
    <main className={css['Auth-wrapper']}>
      {authState.status === 'waiting' && <LinearProgress className={css['Auth-progress']} />}

      <Card className={css.Auth}>
        <Typography className='text-center mb-4 mt-0' variant='h3' component='h3'>
          Iniciar sesión
        </Typography>

        <form
          className='flex-column'
          onSubmit={handleSubmit(values =>
            dispatch({ type: 'auth/signin', payload: { values, enqueueSnackbar } })
          )}>
          <TextField
            className='mb-2'
            error={!!errors.email}
            label='Correo*'
            name='email'
            type='email'
            variant='outlined'
            fullWidth
            helperText={errors.email && errors.email.message}
            inputProps={{
              ref: register({
                required: 'El campo es requerido.',
                // prettier-ignore
                pattern: { value: /\b[\w.-]+@[\w.-]+\.\w{2,4}\b/, message: 'Formato invalido.' }
              })
            }}
          />

          <TextField
            className='mb-3'
            error={!!errors.password}
            label='Contraseña*'
            name='password'
            type='password'
            variant='outlined'
            fullWidth
            helperText={errors.password && errors.password.message}
            inputProps={{
              ref: register({ required: 'El campo es requerido.' })
            }}
          />

          <Button
            variant='contained'
            color='primary'
            type='submit'
            disabled={authState.status === 'waiting'}>
            Iniciar sesión
          </Button>

          {authState.error && (
            <div className='errors mt-3'>
              <Typography paragraph align='center' color='error' variant='body2'>
                {authState.error.data.message}
              </Typography>
            </div>
          )}
        </form>
      </Card>
    </main>
  );
};

export default AuthSignIn;
