import React from 'react';
import ReactDOM from 'react-dom';
import { ThemeProvider } from '@material-ui/styles';
import { Provider } from 'react-redux';
import muiTheme from './muiTheme';
import App from './App';
import * as serviceWorker from './serviceWorker';
import store from './store/store';
import axciosIns from './axios';
import './assets/styles/styles.scss';

const app = (
  <Provider store={store}>
    <ThemeProvider theme={muiTheme}>
      <App />
    </ThemeProvider>
  </Provider>
);

const { dispatch } = store;
const UNAUTHORIZED = 401;
axciosIns.interceptors.response.use(
  response => {
    if (process.env.NODE_ENV !== 'production') {
      console.log(response);
    }
    return response;
  },
  error => {
    if (error.response && error.response.status === UNAUTHORIZED) {
      dispatch.auth.signout();
    }

    return Promise.reject(error);
  }
);

ReactDOM.render(app, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
