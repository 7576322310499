import axios from 'axios';

const axiosInst = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Accept-Language': 'es'
  }
  // baseURL: 'http://localhost:3000/api/backoffice/v1'
});

axiosInst.interceptors.request.use(
  request => {
    const session = JSON.parse(localStorage.getItem('iCanSession'));
    const updatedReq = { ...request };

    if (session && request.url.indexOf('auth') === -1) {
      updatedReq.headers.Authorization = `Bearer ${session.token}`;
    }

    return updatedReq;
  },
  error => {
    console.log(error.response);
    return Promise.reject(error);
  }
);

export default axiosInst;
