import axiosInst from '../../axios';

const crud = {
  state: {
    error: null
  },
  effects: dispatch => ({
    fetchItems(payload) {
      const { params, path, crudId, enqueueSnackbar } = payload;
      let urlParams = '';

      if (params) {
        urlParams = Object.keys(params)
          .map(param => `${param}=${params[param]}`)
          .join('&');
      }
      const url = params ? `${path}?${urlParams}` : path;

      dispatch[crudId].fetchItems();
      axiosInst
        .get(url)
        .then(res => dispatch[crudId].fetchItemsSuccess(res))
        .catch(error => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }

          dispatch[crudId].fetchItemsFailed(error);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    fetchById(payload) {
      const { path, crudId, enqueueSnackbar, afterFuncSuccess } = payload;
      dispatch[crudId].fetchById();
      axiosInst
        .get(path)
        .then(res => {
          if (afterFuncSuccess) {
            afterFuncSuccess(res);
          }
          dispatch[crudId].fetchByIdSuccess(res);
        })
        .catch(error => {
          let errorMessage = error.message;
          if (error.response && error.response.data) {
            errorMessage = error.response.data.message;
            dispatch[crudId].fetchByIdFailed(error.response);
          }
          enqueueSnackbar(errorMessage, { variant: 'error' });
        });
    },

    createUpdate(payload) {
      const {
        method,
        url,
        body,
        crudId,
        afterFuncSuccess,
        afterFuncFailed,
        enqueueSnackbar
      } = payload;

      dispatch[crudId].createUpdate();
      axiosInst[`${method}`](url, body)
        .then(() => {
          if (afterFuncSuccess) {
            afterFuncSuccess();
          }

          dispatch[crudId].createUpdateSuccess();
        })
        .catch(error => {
          let errorMessage = error.message;
          if (error.response && error.response.data) {
            errorMessage = error.response.data.message;
            dispatch[crudId].fetchByIdFailed(error.response);
          }

          if (afterFuncFailed) {
            afterFuncFailed();
          }

          enqueueSnackbar(errorMessage, { variant: 'error' });
        });
    },

    enableDisable(payload) {
      const { url, method, crudId, enqueueSnackbar, afterFuncSuccess, afterFuncFailed } = payload;

      dispatch[crudId].toggleStatus();

      axiosInst[`${method}`](url)
        .then(() => {
          if (afterFuncSuccess) {
            afterFuncSuccess();
          }
          dispatch[crudId].toggleStatusSuccess();
        })
        .catch(error => {
          let errorMessage = error.message;
          if (error.response && error.response.data) {
            errorMessage = error.response.data.message;
          }

          if (afterFuncFailed) {
            afterFuncFailed();
          }

          enqueueSnackbar(errorMessage, { variant: 'error' });
          dispatch[crudId].toggleStatusFailed(error.response);
        });
    },

    delete(payload) {
      const { url, crudId, enqueueSnackbar, afterFuncSuccess, afterFuncFailed } = payload;

      axiosInst
        .delete(url)
        .then(() => {
          if (afterFuncSuccess) {
            afterFuncSuccess();
          }
        })
        .catch(error => {
          let errorMessage = error.message;
          if (error.response && error.response.data) {
            errorMessage = error.response.data.message;
            dispatch[crudId].fetchItemsFailed(error.response);
          }

          if (afterFuncFailed) {
            afterFuncFailed();
          }

          enqueueSnackbar(errorMessage, { variant: 'error' });
        });
    }
  })
};

export default crud;
