import axiosInst from '../../axios';

const recipes = {
  state: {
    status: '',
    currentRecipeStatus: '',
    recipeData: {},
    itemsList: [],
    measurementsList: [],
    recipeTypesList: [],
    countriesList: [],
    allergensList: [],
    dairyIngredientsList: [],
    dietsList: [],
    pagination: {
      totalPages: 1,
      totalObjects: 0,
      currentPage: 1
    },
    error: null
  },
  reducers: {
    initRecipeData: state => ({ ...state, recipeData: {} }),
    fetchById: state => ({
      ...state,
      currentRecipeStatus: 'waiting',
      error: null,
      recipeData: {}
    }),
    fetchByIdSuccess: (state, res) => {
      const data = res.data.data.recipe;
      const ingredients =
        data.recipeIngredients && data.recipeIngredients.length > 0
          ? data.recipeIngredients.map(item => ({
              name: item.ingredient || '',
              ingredientId: item.ingredientId || '',
              unitId: item.measurement && item.measurement.id,
              unit: item.measurement || {},
              newQty: item.quantity || 0,
              quantity:
                item.quantity && item.measurement
                  ? `${item.quantity} ${
                      item.quantity > 1 ? item.measurement.pluralName : item.measurement.fullName
                    }`
                  : '',
              substitutes:
                item.substitutes && item.substitutes.length > 0
                  ? item.substitutes.map(sub => ({
                      name: sub.ingredient || '',
                      ingredientId: sub.ingredientId || '',
                      unitId: sub.measurement && sub.measurement.id,
                      unit: sub.measurement || {},
                      newQty: sub.quantity || 0,
                      quantity:
                        sub.quantity && sub.measurement
                          ? `${sub.quantity} ${
                              sub.quantity > 1
                                ? sub.measurement.pluralName
                                : sub.measurement.fullName
                            }`
                          : ''
                    }))
                  : []
            }))
          : [];

      return {
        ...state,
        currentRecipeStatus: 'received',
        error: null,
        recipeData: { ...data, ingredients }
      };
    },
    fetchByIdFailed: (state, error) => ({ ...state, currentRecipeStatus: 'failed', error }),

    // CREATE/UPDATE ACTIONS
    createUpdate: state => ({ ...state, status: 'waiting', error: null }),
    createUpdateSuccess: state => ({ ...state, status: 'received' }),
    createUpdateFailed: (state, error) => ({ ...state, status: 'failed', error }),

    fetchItems: state => ({ ...state, status: 'waiting', error: null }),
    fetchItemsSuccess: (state, res) => ({
      ...state,
      status: 'received',
      itemsList: res.data.data.entities,
      pagination: res.data.data.pagination
    }),
    fetchItemsFailed: (state, error) => ({ ...state, status: 'failed', error }),

    // RECIPES TOGGLE STATUS ACTIONS
    toggleStatus: state => ({ ...state, status: 'waiting', error: null }),
    toggleStatusSuccess: state => ({ ...state, status: 'received' }),
    toggleStatusFailed: (state, error) => ({
      ...state,
      status: 'failed',
      error
    }),

    fetchBootstrapData: state => ({ ...state, status: 'waiting', error: null }),
    fetchBootstrapDataSuccess: (state, data) => ({
      ...state,
      status: 'received',
      measurementsList: data[0],
      recipeTypesList: data[1],
      countriesList: data[2],
      allergensList: data[3],
      dairyIngredientsList: data[4],
      dietsList: data[5]
    }),
    fetchBootstrapDataFailed: (state, error) => ({ ...state, status: 'failed', error })
  },
  effects: dispatch => ({
    fetchBootstrapData() {
      const promises = [
        axiosInst.get('/recipes/measurements'),
        axiosInst.get('/recipes/types'),
        axiosInst.get('/countries/all'),
        axiosInst.get('/recipes/allergens'),
        axiosInst.get('/recipes/dairies'),
        axiosInst.get('/recipes/diets')
      ];

      Promise.all(promises)
        .then(res => {
          dispatch.recipes.fetchBootstrapDataSuccess(res.map(innerRes => innerRes.data.data));
        })
        .catch(error => {
          dispatch.recipes.fetchBootstrapDataFailed(error);
          console.log(error);
        });
    }
  })
};

export default recipes;
