import axiosInst from '../../axios';
import appHistory from '../../history';

const auth = {
  state: {
    user: null,
    roles: [],
    token: null,
    error: null,
    status: ''
  },
  reducers: {
    // SIGN IN
    signin: state => ({ ...state, status: 'waiting', error: null }),
    signinSuccess: (state, res) => ({
      ...state,
      status: 'received',
      token: res.token,
      user: res.user,
      roles: res.roles.map(role => role.name)
    }),
    signinFailed: (state, error) => ({ ...state, status: 'failed', error }),

    // RECOVER SESSION
    recoverUserSession: (state, payload) => ({
      ...state,
      token: payload.token,
      user: payload.user,
      roles: payload.roles,
      status: 'received'
    })
  },
  effects: dispatch => ({
    // SIGN IN
    signin(payload) {
      const { values, enqueueSnackbar } = payload;

      axiosInst
        .post(`/auth/login`, values)
        .then(res => {
          this.signinSuccess(res.data.data);
          enqueueSnackbar('Sesión iniciada correctamente', { variant: 'success' });
          appHistory.push('/app');
          localStorage.setItem(
            'iCanSession',
            JSON.stringify({
              token: res.data.data.token,
              user: res.data.data.user,
              roles: res.data.data.roles.map(role => role.name)
            })
          );
        })
        .catch(error => {
          this.signinFailed(error.response);
        });
    },

    // SIGN OUT
    signout() {
      localStorage.removeItem('iCanSession');
      dispatch({ type: 'RESET_APP' });
      appHistory.push('/auth');
    }
  })
};

export default auth;
