import { init } from '@rematch/core';
import exercises from './models/exercises';
import recipes from './models/recipes';
import ingredients from './models/ingredients';
import routines from './models/routines';
import plans from './models/plans';
import auth from './models/auth';
import reports from './models/reports';
import crud from './models/crud';
import scholarships from './models/scholarships';
import referals from './models/referals';
import box from './models/box';
import categories from './models/categories';

const store = init({
  redux: {
    rootReducers: {
      RESET_APP: () => undefined
    },
    devtoolOptions: {
      disabled: process.env.NODE_ENV === 'production'
    }
  },
  models: {
    exercises,
    recipes,
    ingredients,
    auth,
    routines,
    plans,
    reports,
    crud,
    scholarships,
    referals,
    box,
    categories
  }
});

export default store;
