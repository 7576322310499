const scholarships = {
  state: {
    status: '',
    scholarshipStatus: '',
    scholarshipData: {},
    itemsList: [],
    pagination: {
      totalPages: 1,
      totalObjects: 0,
      currentPage: 1
    },
    error: null
  },
  reducers: {
    fetchById: state => ({
      ...state,
      scholarshipStatus: 'waiting',
      error: null,
      scholarshipData: {}
    }),
    fetchByIdSuccess: (state, res) => ({
      ...state,
      scholarshipStatus: 'received',
      scholarshipData: res.data.data
    }),
    fetchByIdFailed: (state, error) => ({ ...state, scholarshipStatus: 'failed', error }),
    resetScholarship: state => ({
      ...state,
      scholarshipStatus: '',
      error: null,
      scholarshipData: {}
    }),

    createUpdate: state => ({ ...state, status: 'waiting', error: null }),
    createUpdateSuccess: state => ({ ...state, status: 'received' }),
    createUpdateFailed: (state, error) => ({ ...state, status: 'failed', error }),

    fetchItems: state => ({ ...state, status: 'waiting', error: null }),
    fetchItemsSuccess: (state, res) => ({
      ...state,
      status: 'received',
      itemsList: res.data.data.scholarchips,
      pagination: { ...res.data.data.pagination }
    }),
    fetchItemsFailed: (state, error) => ({ ...state, status: 'failed', error }),

    toggleStatus: state => ({ ...state, status: 'waiting', error: null }),
    toggleStatusSuccess: state => ({ ...state, status: 'received' }),
    toggleStatusFailed: (state, error) => ({ ...state, status: 'failed', error })
  }
};

export default scholarships;
