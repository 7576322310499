import axiosInst from '../../axios';
import appHistory from '../../history';

const exercises = {
  state: {
    status: '',
    exerciseStatus: '',
    itemsList: [],
    exerciseData: {},
    pagination: {
      totalPages: 1,
      totalObjects: 0,
      currentPage: 1
    },
    error: null,
    bodyParts: [],
    bodyZones: [],
    equipment: [],
    exercisingExperiences: [],
    goals: [],
    positions: [],
    exercisesSimpleList: []
  },
  reducers: {
    fetchById: state => ({
      ...state,
      exerciseStatus: 'waiting',
      error: null,
      exerciseData: {}
    }),
    fetchByIdSuccess: (state, res) => ({
      ...state,
      exerciseStatus: 'received',
      exerciseData: res.data.data.exercise
    }),
    fetchByIdFailed: (state, error) => ({ ...state, exerciseStatus: 'failed', error }),
    resetExercise: state => ({ ...state, exerciseStatus: '', error: null, exerciseData: {} }),

    exercise: state => ({ ...state, status: 'waiting', error: null }),
    exerciseReqSuccess: state => ({ ...state, status: 'received' }),
    exerciseReqFailed: (state, error) => ({ ...state, status: 'failed', error }),

    fetchItems: state => ({ ...state, status: 'waiting', error: null }),
    fetchItemsSuccess: (state, res) => ({
      ...state,
      status: 'received',
      itemsList: res.data.data.exercises,
      pagination: { ...res.data.data.pagination }
    }),
    fetchItemsFailed: (state, error) => ({ ...state, status: 'failed', error }),

    toggleStatus: state => ({ ...state, status: 'waiting', error: null }),
    toggleStatusSuccess: state => ({ ...state, status: 'received' }),
    toggleStatusFailed: (state, error) => ({ ...state, status: 'failed', error }),

    fetchBootstrapData: state => ({ ...state, status: 'waiting', error: null }),
    fetchBootstrapDataSuccess: (state, data) => ({
      ...state,
      status: 'received',
      ...data[0],
      positions: data[1].items,
      exercisesSimpleList: data[2].exercises
    }),
    fetchBootstrapDataFailed: (state, error) => ({ ...state, status: 'failed', error })
  },
  effects: dispatch => ({
    // CREATE/UPDATE EXERCISES REQUEST
    exerciseReq({ method, body, id, enqueueSnackbar }) {
      const url = method === 'post' ? '/exercises' : `/exercises/${id}`;
      const message =
        method === 'post'
          ? 'Ejercicio creado correctamente.'
          : 'Ejercicio actualizado correctamente.';

      this.exercise();
      axiosInst[`${method}`](url, body)
        .then(() => {
          this.exerciseReqSuccess();
          appHistory.push('/app/exercises');
          enqueueSnackbar(message, { variant: 'success' });
        })
        .catch(error => {
          let msg = error.message;

          if (error.response) {
            msg = error.response.data.message;
          }

          this.exerciseReqFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    fetchBootstrapData() {
      const promises = [
        axiosInst.get('/exercises/bootstrap'),
        axiosInst.get('/exercises/positions'),
        axiosInst.get('/exercises/list/all')
      ];

      Promise.all(promises)
        .then(res => {
          dispatch.exercises.fetchBootstrapDataSuccess(res.map(innerRes => innerRes.data.data));
        })
        .catch(error => {
          dispatch.exercises.fetchBootstrapDataFailed(error);
          console.log(error);
        });
    }
  })
};

export default exercises;
