const referals = {
  state: {
    status: '',
    referalStatus: '',
    referalData: {},
    itemsList: [],
    pagination: {
      totalPages: 1,
      totalObjects: 0,
      currentPage: 1
    },
    error: null
  },
  reducers: {
    fetchByIdSuccess: (state, res) => ({
      ...state,
      referalStatus: 'received',
      referalData: res.data.data
    }),
    fetchByIdFailed: (state, error) => ({ ...state, referalStatus: 'failed', error }),
    resetReferal: state => ({
      ...state,
      referalStatus: '',
      error: null,
      referalData: {}
    }),
    fetchItems: state => ({ ...state, status: 'waiting', error: null }),
    fetchItemsFailed: (state, error) => ({ ...state, status: 'failed', error }),
    fetchItemsSuccess: (state, res) => ({
      ...state,
      status: 'received',
      itemsList: res.data.data.entities,
      pagination: { ...res.data.data.pagination }
    }),
    toggleStatusFailed: (state, error) => ({ ...state, status: 'failed', error })
  }
};

export default referals;
