const reports = {
  state: {
    status: '',
    itemsList: [],
    error: null
  },
  reducers: {
    fetchItems: state => ({ ...state, status: 'waiting', error: null }),
    fetchItemsSuccess: (state, res) => ({
      ...state,
      status: 'received',
      itemsList: res.data.data.reports
    }),
    fetchItemsFailed: (state, error) => ({ ...state, status: 'failed', error }),

    createUpdate: state => ({ ...state, status: 'waiting', error: null }),
    createUpdateSuccess: state => ({ ...state, status: 'received' }),
    createUpdateFailed: (state, error) => ({ ...state, status: 'failed', error })
  }
};

export default reports;
