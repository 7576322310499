import axiosInst from '../../axios';

const ingredients = {
  state: {
    status: '',
    itemsList: [],
    itemsSimpleList: [],
    pagination: {
      totalPages: 1,
      totalObjects: 0,
      currentPage: 1
    },
    error: null
  },
  reducers: {
    createUpdate: state => ({ ...state, status: 'waiting', error: null }),
    createUpdateSuccess: state => ({ ...state, status: 'received' }),
    createUpdateFailed: (state, error) => ({ ...state, status: 'failed', error }),

    fetchSimpleList: state => ({ ...state, status: 'waiting', error: null }),
    fetchSimpleListSuccess: (state, data) => ({
      ...state,
      status: 'received',
      itemsSimpleList: data
    }),
    fetchSimpleListFailed: (state, error) => ({ ...state, status: 'failed', error }),

    fetchItems: state => ({ ...state, status: 'waiting', error: null }),
    fetchItemsSuccess: (state, res) => ({
      ...state,
      status: 'received',
      itemsList: res.data.data.ingredients,
      pagination: res.data.data.pagination
    }),
    fetchItemsFailed: (state, error) => ({ ...state, status: 'failed', error }),

    resetIngredientData: state => ({ ...state, ingredientData: {} })
  },
  effects: dispatch => ({
    fetchSimpleList(enqueueSnackbar) {
      axiosInst
        .get('/ingredients/all')
        .then(res => {
          dispatch.ingredients.fetchSimpleListSuccess(res.data.data);
        })
        .catch(error => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
          }

          dispatch.ingredients.fetchSimpleListFailed(error);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    }
  })
};

export default ingredients;
