import React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import css from './Loader.module.scss';

const Loader = () => {
  return (
    <div className={css.Loader}>
      <CircularProgress size={50} />
    </div>
  );
};

export default Loader;
