import axiosInst from '../../axios';
import appHistory from '../../history';

const plans = {
  state: {
    status: '',
    itemsList: [],
    planStatus: '',
    planData: {},
    routinesList: [],
    levelsList: [],
    goalsList: [],
    pagination: {
      totalPages: 1,
      totalObjects: 0,
      currentPage: 1
    },
    error: null
  },
  reducers: {
    fetchById: state => ({
      ...state,
      planStatus: 'waiting',
      error: null,
      planData: {}
    }),
    fetchByIdSuccess: (state, res) => ({
      ...state,
      planStatus: 'received',
      planData: res.data.data
    }),
    fetchByIdFailed: (state, error) => ({ ...state, planStatus: 'failed', error }),
    resetPlan: state => ({ ...state, planStatus: '', error: null, planData: {} }),

    createUpdatePlan: state => ({ ...state, status: 'waiting', error: null }),
    createUpdatePlanSuccess: state => ({ ...state, status: 'received' }),
    createUpdatePlanFailed: (state, error) => ({ ...state, status: 'failed', error }),

    fetchBootstrapData: state => ({ ...state, status: 'waiting', error: null }),
    fetchBootstrapDataSuccess: (state, data) => ({
      ...state,
      status: 'received',
      routinesList: data[0],
      levelsList: data[1],
      goalsList: data[2].goals
    }),
    fetchBootstrapDataFailed: (state, error) => ({ ...state, status: 'failed', error }),
    fetchItems: state => ({ ...state, status: 'waiting', error: null }),
    fetchItemsSuccess: (state, res) => ({
      ...state,
      status: 'received',
      itemsList: res.data.data.plans,
      pagination: { ...res.data.data.pagination }
    }),
    fetchItemsFailed: (state, error) => ({ ...state, status: 'failed', error }),

    toggleStatus: state => ({ ...state, status: 'waiting', error: null }),
    toggleStatusSuccess: state => ({ ...state, status: 'received' }),
    toggleStatusFailed: (state, error) => ({ ...state, status: 'failed', error })
  },
  effects: dispatch => ({
    createUpdatePlan(payload) {
      const { method, body, id, enqueueSnackbar } = payload;
      const url = method === 'post' ? '/training-plans' : `/training-plans/${id}`;

      const message =
        method === 'post' ? 'Plan creadd correctamente.' : 'Plan actualizado correctamente.';

      axiosInst[`${method}`](url, body)
        .then(() => {
          dispatch.plans.createUpdatePlanSuccess();
          appHistory.push('/app/plans');
          enqueueSnackbar(message, { variant: 'success' });
        })
        .catch(error => {
          let msg = error.message;
          if (error.response) {
            msg = error.response.data.message;
            console.log(msg);
          }

          this.createUpdatePlanFailed(error.response);
          enqueueSnackbar(msg, { variant: 'error' });
        });
    },

    fetchBootstrapData() {
      const promises = [
        axiosInst.get('/routines/list'),
        axiosInst.get('/exercising-experiences/list'),
        axiosInst.get('/exercises/bootstrap')
      ];

      Promise.all(promises)
        .then(res => {
          dispatch.plans.fetchBootstrapDataSuccess(res.map(innerRes => innerRes.data.data));
        })
        .catch(error => {
          dispatch.plans.fetchBootstrapDataFailed(error);
          console.log(error);
        });
    }
  })
};

export default plans;
